import React, { FC, useState } from "react";
import {
  SubscriptionPlan,
  useUnsplashPhotoLazyQuery,
} from "../../generated/graphql";
import { PointsLoader } from "../../ui/Loader/Loader";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SelectableItem from "./content/SelectableItem";

interface Props {
  selected: string;
  columns?: number;
  onSelect: (id: any) => void;
}

interface UnsplashThumbnailProps {
  name: string;
  link: string;
  image: string;
}

const UnsplashThumbnail: FC<UnsplashThumbnailProps> = ({
  name,
  link,
  image,
}) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        width: "100%",
        display: "flex",
        height: "300px",
        alignItems: "flex-end",
        p: 1,
        borderRadius: 2,
      }}
    >
      <Typography
        component="a"
        sx={{
          color: "white",
          fontSize: "14px",
        }}
        href={link + "?utm_source=JoyQR&utm_medium=referral "}
        target="_blank"
      >
        {name}
      </Typography>
    </Box>
  );
};

const Unsplash: FC<Props> = ({ selected, onSelect, columns = 4 }) => {
  const [unsplashSearch, setUnsplashSearch] = useState("");
  const [searchPhotos, { loading, data: photos }] = useUnsplashPhotoLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const confirmSearch = (e: any) => {
    if (e.keyCode === 13) {
      searchPhotos({
        variables: {
          search: unsplashSearch,
        },
      });
    }
  };

  const searchAfterClick = (e: any) => {
    searchPhotos({
      variables: {
        search: unsplashSearch,
      },
    });
  };

  const onChangeSearch = (e: any) => setUnsplashSearch(e.target.value);

  return (
    <Stack>
      <TextField
        InputProps={{
          endAdornment: (
            <SearchIcon
              onClick={searchAfterClick}
              sx={{
                cursor: "pointer",
              }}
            />
          ),
          disableUnderline: true,
        }}
        onChange={onChangeSearch}
        label="Search  Gallery"
        onKeyDown={confirmSearch}
      />
      <Typography fontSize={"14px"} mb={1} mt={columns}>
        Photos by{" "}
        <Typography
          component="a"
          target="_blank"
          href="https://unsplash.com/?utm_source=JoyQR&utm_medium=referral"
        >
          Unsplash
        </Typography>
      </Typography>

      {loading && (
        <PointsLoader
          style={{
            margin: "auto",
          }}
        />
      )}

      {photos?.getUnsplashPhotos && (
        <Grid container spacing={3}>
          {photos.getUnsplashPhotos.map((photo: any) => (
            <Grid key={photo.rawUrl} item md={4} ms={6} xs={12}>
              <SelectableItem
                sx={{
                  p: 0,
                }}
                minPlan={SubscriptionPlan.Plus}
                id={photo.rawUrl + "*" + photo.downloadLocation}
                isSelected={photo.rawUrl === selected}
                onClick={onSelect}
              >
                <UnsplashThumbnail
                  name={photo.autor.name}
                  link={photo.autor.unsplashLink}
                  image={
                    photo.rawUrl + "?q=80&fm=jpg&crop=entropy&fit=max&h=300" ||
                    ""
                  }
                />
              </SelectableItem>
            </Grid>
          ))}
        </Grid>
      )}
    </Stack>
  );
};

export default Unsplash;
