import Head from "next/head";
import React, { FC } from "react";

interface Props {
  title: string;
}

const Meta: FC<Props> = ({ title }) => {
  const t = `${title} | JoyQR.com`;
  return (
    <Head>
      <title>{t}</title>
    </Head>
  );
};

export default Meta;
