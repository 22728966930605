import { Box, Button, Stack, Typography } from "@mui/material";
import React, { FC, useRef } from "react";
import { Cropper } from "react-cropper";
import { InputGroup } from "../../../ui";
import "cropperjs/dist/cropper.css";
import Modal from "./Modal";
interface Props {
  image: string;
  onCrop: (image: any) => void;
  onClose: () => void;
}

const CropperModal: FC<Props> = ({ image, onCrop, onClose }) => {
  const cropperRef = useRef<HTMLImageElement>(null);

  const _onClose = () => {
    onClose();
  };

  const _onSave = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const format = image.split(":")[1].split(";")[0];

    cropper.getCroppedCanvas().toBlob(
      (blob: any) => {
        onCrop({
          preview: cropper.getCroppedCanvas().toDataURL() || "", //URL.createObjectURL(acceptedFiles[0]),
          file: blob,
        });
        onClose();
      },
      "image/jpeg",
      0.9
    );
  };

  return (
    <Modal title="Crop Image" close={onClose}>
      <Cropper
        src={image}
        style={{ height: 400, width: "100%" }}
        initialAspectRatio={1}
        aspectRatio={1}
        guides={false}
        background={false}
        //   crop={_onCrop}
        zoomable={false}
        ref={cropperRef}
      />
      <Stack width={"270px"} alignItems="center" mt={2}>
        <Button
          onClick={_onSave}
          sx={{
            maxWidth: "270px",
            width: "100%",
          }}
          variant="primary"
        >
          Save
        </Button>
        <Button
          onClick={_onClose}
          variant="link"
          sx={{
            color: "error.main",
            "&:hover": {
              color: "error.hover",
            },
            maxWidth: "270px",
            height: "40px",
            width: "fit-content",
          }}
        >
          Cancel
        </Button>
      </Stack>
    </Modal>
  );
};

export default CropperModal;
